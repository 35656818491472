<template lang="pug">
  .toast-body
    .applied-status {{ $t(`prices_exports.${status}`) }}
    .desc
      .desc-block
        .title {{ $t("general_settings.rule_settings.insurance") }}
        .value {{ $t(`general_settings.rule_settings.${data.insurance}`) }}
      .desc-block
        .title {{ this.$t("shop_matching.shop_name") }}
        .value {{ data.shop.name }}
      .desc-block
        .title {{ this.$t("car_class_matching.car_class_name") }}
        .value {{ data.car_class.name }}
    .desc
      .desc-block
        .title {{ $t("prices_exports.applied_dates") }}
        .value(
          v-if="isEmpty(data.applied_changes)"
        ) —
        .value(v-else)
          div(
            v-for="({ from, to }, index) in data.applied_changes"
            :key="index"
          ) {{ from === to ? from : $t("date.period.short", { from, to }) }}
      template(v-if="!isEmpty(data.unapplied_changes)")
        .desc-block
          .title {{ $t("prices_exports.unapplied_dates") }}
          .value
            div(
              v-for="({ from, to }, index) in data.unapplied_changes"
              :key="index"
            ) {{ from === to ? from : $t("date.period.short", { from, to }) }}

        .applied-status {{ $t("prices_exports.all_unapplied_prices_will_revert") }}
    .buttons
      BButton.action.view(
        size="sm"
        @click="goToPage"
      ) {{ $t("price_calendar.calendar.price_modal.save") }}

      BButton.action(
        :variant="variant"
        size="sm"
        @click="$emit('close')"
      ) {{ $t("actions.close") }}
</template>

<script>
  import { isEmpty, isEqual, pick } from "lodash-es"
  import { randomString } from "@/helpers/common"
  import { BButton } from "bootstrap-vue"

  export default {
    components: {
      BButton
    },

    props: {
      data: {
        type: Object,
        required: true
      },
      ota: {
        type: Object,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      status: {
        type: String,
        required: true
      }
    },

    computed: {
      isSamePage() {
        const { name, query: routeQuery } = this.$route
        const queryKeys = ["ota_id", "shop_id", "car_class_id"]
        const redirectQuery = this.query()

        return name == "BasicSettings" && isEqual(pick(redirectQuery, queryKeys), pick(routeQuery, queryKeys))
      }
    },

    methods: {
      isEmpty,

      query() {
        const data = this.data

        return {
          ota_id: String(data.ota_id),
          shop_id: String(data.shop.id),
          car_class_id: String(data.car_class.id),
          insurance: data.insurance,
          _key: randomString()
        }
      },

      async goToPage() {
        await this.$router.push({ name: "PriceCalendar", query: this.query() }).catch(() => {})
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  .toast-body
    padding: 0

    .applied-status
      text-align: center
      padding: 5px 7px 0 7px
      font-weight: 700
      // background: transparentize($default-white, 0.3)

    .desc
      padding: 7px
      display: flex
      flex-direction: column
      gap: 7px

      .desc-block
        border-radius: 4px
        border: 1px solid #0000000d
        text-align: center
        overflow: hidden

        .title
          color: $default-black
          background: transparentize($default-white, 0.2)
          padding: 3px 0

        .value
          background: transparentize($default-white, 0.7)
          padding: 5px 0

    .buttons
      display: flex
      flex-wrap: nowrap
      gap: 5px
      padding: 0 7px 7px 7px
      white-space: nowrap

      .action
        display: block
        width: 100%
        line-height: 15px
        height: 27px
        font-weight: 300
        border: transparent

        &.btn-success
          background: $default-green

          &:hover
            background: darken($default-green, 4%)

        &.view
          background: transparentize($default-black, 0.35)

          &:hover
            background: transparentize($default-black, 0.25)
</style>
