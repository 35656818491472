<template lang="pug"></template>

<script>
  import { mapGetters } from "vuex"
  import { find, isEmpty } from "lodash-es"
  import withWebSocket from "@/mixins/withWebSocket"

  import ToastTitle from "./ToastTitle"
  import ToastBody from "./ToastBody"

  export default {
    mixins: [withWebSocket],

    mounted() {
      this.webSocketSubscribe("PriceChannel", { received: this.showToast })
    },

    data() {
      return {
        count: 0
      }
    },

    computed: mapGetters(["translatedOtaList"]),

    methods: {
      status(data) {
        if (isEmpty(data.unapplied_changes) && !isEmpty(data.applied_changes)) {
          return "finished"
        } else if (!isEmpty(data.unapplied_changes) && !isEmpty(data.applied_changes)) {
          return "finished_partially"
        } else {
          return "failed"
        }
      },

      showToast(data) {
        const h = this.$createElement
        const status = this.status(data)
        const id = `price-toast-${this.count++}`
        const variant = status === "finished" ? "success" : status === "finished_partially" ? "primary" : "danger"
        const ota = find(this.translatedOtaList, { id: Number(data.ota_id) })

        const vNodesTitle = [
          h(ToastTitle, {
            props: {
              data,
              ota,
              variant
            }
          })
        ]

        const vNodesContent = [
          h(ToastBody, {
            props: {
              data,
              ota,
              variant,
              status
            },
            on: {
              close: () => this.$bvToast.hide(id)
            }
          })
        ]

        this.$bvToast.toast(vNodesContent, {
          id,
          toastClass: "price-consumer-toast",
          title: vNodesTitle,
          solid: true,
          variant,
          noAutoHide: true,
          noCloseButton: true
        })
      }
    }
  }
</script>
