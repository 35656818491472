<template lang="pug">
  .toast-title
    .d-flex
      .label(:class="variant") {{ ota.name }}
      .label {{ $t("prices_exports.title") }}
    AppTimer(:start="timerStartDate")
</template>

<script>
  export default {
    components: {
      AppTimer: () => import("@/components/elements/AppTimer")
    },

    props: {
      data: {
        type: Object,
        required: true
      },
      ota: {
        type: Object,
        required: true
      },
      variant: {
        type: String,
        required: true
      }
    },

    computed: {
      timerStartDate() {
        return new Date(this.data.timestamp * 1000)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .toast-title
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 0
    padding: 3px 0
    width: 100%

    .timer
      font-weight: 500
      font-size: 0.7rem

    .label
      height: 25px
      font-size: 0.9rem
      border-radius: 3px
      padding: 2px 6px
      background: transparentize($default-white, 0.3)
      margin-right: 5px
      color: $default-black

      &.success
        color: $default-white
        background: $default-green

      &.danger
        color: $default-white
        background: $default-red

      &.default,
      &.primary
        color: $default-white
        background: $default-blue
</style>
